import React, {useEffect, useState, useContext} from "react"
import { message, Row, Col,  Drawer, Button, Table, Space, TimePicker, Typography } from "antd"
import Icon from '@ant-design/icons';
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import bookings_icon_svg from '../assets/svgs/bookings_icon.svg'
import { CSSTransition } from 'react-transition-group';
import { APICreateTimeslot } from "../scripts/experiences"
import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Title } = Typography;
const BookingsIcon = props => <Icon className="wtc-icon bookings-icon" component={bookings_icon_svg} {...props} />;


const ViewBookingDrawer = (props) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [loading, setLoading] = useState(false)
  const [showNewTimeslot, setShowNewTimeslot] = useState(false)
  const [newBooking, setNewBooking] = useState(false)
  const [newTimeslotShown, setNewTimeslotShown] = useState(false)

  const columns = [
    {
      width: "64px",
      render: (text, record) => {
        return (
        <div className="filler"></div>
        )
      }
    },
    {
      title: 'Timeslot',
      render: (text, record) => {
        return (
        <div key={record.id}>{moment(record.start).format("h:mm A")} - {moment(record.finish).format("hh:mm A")}</div>
        )
      }
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity'
    },
    {
      title: 'Remaining Slots',
      dataIndex: 'remaining',
      render: (text,record) => {
        return record.remaining > 0 ? record.remaining : <b>0</b>
      }
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
        <Space className="actions">
          <Button type="link" disabled={record.remaining === 0} onClick={() => handleCreateBooking(record)}>Create Booking</Button>
          {/* <Button onClick={() => console.log("coming soon")}>Export</Button> */}
        </Space>)
      }
    },
    {
      width: "64px",
      render: (text, record) => {
        return (
        <div className="filler"></div>
        )
      }
    }
  ];

  function onChange(time, timeString) {
    console.log(time, timeString);
    dispatch({ type:'NEW_TIMESLOT', start: time});
  }

  const createNewTimeslot = async () => {
    setLoading(true)
    let create_timeslot_request = {
      experience_id: state.experienceSelected.id,
      start: `${state.daySelected}T${state.newTimeslotStart.format("HH:mm:")}00${moment().format("Z")}`,
    }

    console.log(`${state.daySelected}T${state.newTimeslotStart.format("HH:mm:")}00${moment().format("Z")}`)

    // console.log(moment(`${state.daySelected}T${state.newTimeslotStart.format("HH:mm Z")}`).utc().format("YYYYMMDDTHHmm"))//.utcOffset(moment().utcOffset()).format())
    // console.log(`${moment(state.daySelected).format("YYYYMMDD")}T${state.newTimeslotStart.format("HHmmzz").utc()}`)//.utcOffset(moment().utcOffset()).format("X"))

    console.log(create_timeslot_request)
    await APICreateTimeslot(create_timeslot_request)
      .then(response => {
        setLoading(false)
        console.log(response)
        // if(responsestatusCode === 200){
        // console.log(response)
        setLoading(false)
        setShowNewTimeslot(false)
        message.info("New achedule added!")
        // props.onSuccess("Timeslot created!");  
        dispatch({ type:'REFRESH', toRefresh: 'refreshFrames'});
      // }
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        if(error.type === "validation"){
          message.error("Error: No User deleted")
        }
        else{
          message.error("An error occured!")
        }
      })
  }


  useEffect(() => {
    if(newBooking){
      // props.onCreateClick()
      setNewBooking(false)
    }
  }, [newBooking, state.timeslotSelected])

  const handleCreateBooking = async (timeslot) => {
    if(timeslot){
      setNewBooking(true)
      dispatch({ type:'SELECT', toSelect: 'timeslotSelected', value: timeslot});
    }
  }

  useEffect(() => {
    console.log(props)
  }, [props])

  const handleClose = () => {
    setShowNewTimeslot(false)
    setNewTimeslotShown(false)
    props.onClose();
  }

  const renderNewTimeslot = () => {
    return (<div>
      <CSSTransition
          in={!showNewTimeslot && !newTimeslotShown }
          timeout={200}
          classNames="new-timeslot"
          // unmountOnExit={false}
          // onEntering={{ opacity: [0, 1] }}
          // onExiting={{ opacity: [1, 0]}}
          onExited={() => { setNewTimeslotShown(true) }}
          easing="linear"
        >
          <div className="new-timeslot">
            <Button type="primary" size="large" onClick={() => setShowNewTimeslot(true)}>Add Schedule</Button>
          </div>
      </CSSTransition>
      <CSSTransition
          in={showNewTimeslot && newTimeslotShown}
          timeout={200}
          classNames="new-timeslot"
          mountOnEnter={true}
          // unmountOnExit={false}
          // onEntering={{ opacity: [0, 1] }}
          // onExiting={{ opacity: [1, 0]}}
          onExited={() => { dispatch({ type:'NEW_TIMESLOT', start: undefined}); setNewTimeslotShown(false) }}
          easing="linear"
        >
          <div className="new-timeslot">
            <Space size={20}>
              <TimePicker inputReadOnly	use12Hours value={state.newTimeslotStart} format="h:mm A" onChange={onChange} minuteStep={15} style={{width: "250px", padding: "4px 11px", boxSizing: "content-box"}} size="large" />
              <Button block type="primary" disabled={!state.newTimeslotStart} loading={loading} size="large" onClick={createNewTimeslot} >Create Schedule</Button>
              <Button type="link" onClick={() => setShowNewTimeslot(false) }>Cancel</Button>
            </Space>
          </div>
      </CSSTransition>  
      </div>
    )
  }

  return (
    <Drawer
      className="view-date-drawer"
      width={860}
      onClose={handleClose}
      visible={props.visible}
      bodyStyle={{ padding: 0 }}
    >
      <Row gutter={20} className="header">
        <Col flex="20px">
          <BookingsIcon style={{fontSize: "45px"}}/>
        </Col>
        <Col flex="auto" style={{maxWidth: "calc(100% - 95px)"}}>
          <Row>
            <Col span={24}>
              <Title level={2}>{moment(state.daySelected).format("dddd, MMMM Do YYYY") || ""}</Title>
            </Col>
            <Col span={24}>
              <Title className="text-light" level={5} type="secondary">{state.experienceSelected ? state.experienceSelected.title : ""}</Title>
            </Col>
          </Row> 
        </Col>
      </Row>
      <Table footer={renderNewTimeslot} pagination={{ defaultPageSize: 5 }} loading={!state.framesLoaded} className="timeslot-table" columns={columns} rowKey={"id"} dataSource={props.frames ? props.frames.timeslots : []} />
       {/* {renderNewTimeslot()} */}
    </Drawer>
   )
}
export default ViewBookingDrawer


