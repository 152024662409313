import React, { useEffect } from "react"
import { Row, Col, Card, Typography, Space } from "antd"
import Icon from '@ant-design/icons';
import view_icon_svg from '../assets/svgs/view_icon.svg'
import calendar_icon_svg from '../assets/svgs/calendar_icon.svg'
import bookings_icon_svg from '../assets/svgs/bookings_icon.svg'
import ExperienceImage from './experience_Image';

import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');
const { Title } = Typography;

const CalendarIcon = props => <Icon className="wtc-icon calendar-icon" component={calendar_icon_svg} {...props} />;
const ViewIcon = props => <Icon className="wtc-icon view-icon" component={view_icon_svg} {...props} />;
const BookingsIcon = props => <Icon className="wtc-icon bookings-icon" component={bookings_icon_svg} {...props} />;


const ExperienceCard = (props) => {
  useEffect(() => {
    console.log("EXPERIENCE:", props.experience)
  }, [props.experience])
  return (
    <Card className="experience-card" onClick={props.onClick}>
      <Row gutter={20} justify="space-between" style={{height: "100%"}}>
        <Col span={6}>
          <ExperienceImage experience_image={props.experience.images.length > 0 ? props.experience.images[0].src : "no-image"} />
        </Col>
        <Col span={18} className="details">
          <Title level={3}>{props.experience.title}</Title>
          <Space size={"large"} align="center">
            <a href={`${process.env.GATSBY_SHOPIFY_STORE_DOMAIN}/products/${props.experience.handle}`} target="_blank" rel="noreferrer">
              <Space size={10} ><ViewIcon />View</Space>
            </a>
            <Space size={10} onClick={props.onPriceClick}><BookingsIcon /> Price</Space>
            <Space size={10} onClick={props.onCalendarClick}><CalendarIcon /> Calendar</Space>
          </Space>
        </Col>
      </Row>
    </Card>
   )
}

export default ExperienceCard

ExperienceCard.defaultProps = {
  onViewClick: () => {},
  onPriceClick: () => {},
  onCalendarClick: () => {}
}