import React, { useContext, useState } from "react"
import { message, Calendar, Card } from "antd"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import moment from "moment";
import ExperienceViewDateDrawer from "./experience_ViewDateDrawer"
import CreateBookingDrawer from "./booking_CreateDrawer"

const ExperienceViewCalendar = (props) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [showViewDateDrawer, setShowViewDateDrawer] = useState(false)
  const [showCreateBookingDrawer, setShowCreateBookingDrawer] = useState(false)

  const onPanelChange = (value, mode) => {
    setShowViewDateDrawer(false)
    dispatch({ type:'SELECT', toSelect: 'monthSelected', value });
  }

  const checkDisabledDate = (date_moment) => {
    let date_string = moment(date_moment).format("YYYY-MM-DD")
    if(!state.framesLoaded) return true;
    if(moment(date_string).month() !== moment(state.monthSelected).month()) return true;
    return state.dateDictionary[date_string] ? state.dateDictionary[date_string].blackout ?  true : false : false;
  }
  const dateCellRender = (date_moment) => {
    var style = {};
    let className = "experience-calendar-card";
    let date_string = moment(date_moment).format("YYYY-MM-DD");
    let blackout = false;
    let timeslots = [];

    if(!state.framesLoaded || !state.dateDictionaryProcessed ||  moment(date_string).month() !== moment(state.monthSelected).month()){
      className += " disabled-card"
    }
    else{
      let date_data = state.dateDictionary[date_string] || undefined;
      
      if(date_data){
        if( date_data.blackout ){
          blackout = true;
          className += " blackout-card"
        }
        else{
          className += " available-card"
          timeslots = date_data.timeslots;
        }
      }
      else{
        className += " default-card"
      }
    }
    // <Card className={className} onClick={disabled ? () => {} : () => handleSelectDate(date_moment)} style={style}></Card>
    return <Card className={className} style={style}>
      <div className="date-container">
        <div className="date-number">{date_moment.format('DD')}</div>
        <div className="date-blackout"><b>{blackout && "Blackout" }</b></div>
        {/* <div className="date-available"><i>{!blackout && timeslots.length > 0 && "Availability:" }</i></div> */}
        <div className="date-timeslots">
          {renderDateTimeslots(timeslots)}
        </div>
      </div>
    </Card>
  }

  const renderDateTimeslots = (timeslots) => {
    if(timeslots.length <= 0) return;
    let timeslots_items = [];
    for(let i = 0; i < Math.min(timeslots.length, 4); i++) {
      timeslots_items.push(<span className="timeslot" key={i}>{moment(timeslots[i].start).format("h:mmA")}: {timeslots[i].remaining === 0 ? <b>{timeslots[i].remaining}</b> : timeslots[i].remaining} left</span>)
    }
    if(timeslots.length > 4)
      timeslots_items.push(<span className="timeslot view-more" key="view-more">View more</span>)
    return timeslots_items;
  }
  
  const handleSelectDate = (date_moment) => {
    dispatch({ type:'SELECT', toSelect: 'daySelected', value: date_moment.format("YYYY-MM-DD")});
    setShowViewDateDrawer(true)
  }

  const handleOnCreateClick = () => {
    setShowCreateBookingDrawer(true)
  }

  const handleOnCreate = (response) => {
    message.info(response);
    dispatch({ type:'REFRESH', toRefresh: 'refreshFrames'});
    dispatch({ type:'REFRESH', toRefresh: 'refreshBookings'});
    setShowCreateBookingDrawer(false)
  }

  return (
    <div id="experience-calender">
      <Calendar 
        onSelect={handleSelectDate}
        fullscreen={false}
        disabledDate={checkDisabledDate}
        headerRender={() => <div></div>}
        value={state.monthSelected}
        dateFullCellRender={dateCellRender} 
        onPanelChange={onPanelChange} />
      <ExperienceViewDateDrawer
        onCreateClick={handleOnCreateClick}
        visible={showViewDateDrawer}
        frames={state.dateDictionary[state.daySelected]}
        onClose={() => setShowViewDateDrawer(false)}
        />
      <CreateBookingDrawer
        visible={showCreateBookingDrawer}
        onClose={() => setShowCreateBookingDrawer(false)}
        onSuccess={handleOnCreate} 
        /> 
    </div>
   )
}
ExperienceViewCalendar.defaultProps = {
  experience : {}
}
export default ExperienceViewCalendar

