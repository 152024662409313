import React, { useEffect, useContext, useState} from "react"
import { message, Button, Row, Col, Form, Card, InputNumber, Modal, Divider, Typography } from "antd"
import { APIEditPrices } from "../scripts/experiences"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Title, Text } = Typography;

const ExperienceEditPriceModal = (props) => {
  const [loading, setLoading] = useState(false)
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  // Form 
  const [form] = Form.useForm();
  
  useEffect(() => {
    console.log(props.experience)
  }, [props])

  const onFormChange = (name, info) => {
    console.log(name, info)
  }

  const onFormSubmit = async (data) => {
    console.log(data)

    let items = []
    Object.keys(data).forEach(form_item => {
      // console.log(form_item)
      if(form.isFieldTouched(form_item)){
        if(form_item.includes(`variant_${state.experienceSelected.external_id}`) && data[form_item] > 0){
          let item = {
            external_id : form_item.replace(`variant_${state.experienceSelected.external_id}_`, ""),
            price: data[form_item]
          }
          items.push(item)
        }
      }
    })
    console.log(items)


    let edit_price_request = {
      variants: items
    }
    setLoading(true)
    await APIEditPrices(state.experienceSelected.id, edit_price_request)
    .then(response => {
      setLoading(false)
      console.log(response)
      setLoading(false)
      dispatch({ type:'REFRESH', toRefresh: 'refreshExperiences'});
      props.onSuccess();
    // }
    })
    .catch(error => {
      console.error(error)
      setLoading(false)
      if(error.type === "validation"){
        message.error("Error: No User deleted")
      }
      else{
        message.error("An error occured!")
      }
    })
  }

  const renderVariantEditor = () => {
    let variant_numbers = [];
    if(state.experienceSelected){
      state.experienceSelected.variants.forEach((variant, index) => {
        let variant_picker = (
            <Form.Item
              key={`${state.experienceSelected.external_id}_${index}`}
              name={`variant_${state.experienceSelected.external_id}_${variant.external_id}`}
              // style={{width: '100%'}}
              label={variant.title}
              // labelCol={{span: 12}}
              // labelAlign="left"
              initialValue={variant.price}
              // wrapperCol={{span: 12}}
            >
              <InputNumber 
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
            </Form.Item>
        )
        variant_numbers.push(variant_picker)
      })
    }
    if(variant_numbers.length === 0){
      variant_numbers.push(<Card key={"waiting"} className={"timeslot-card"}><h4>Please select an experience</h4></Card>)
    }
    return <Col span={18} offset={3} className="variants">{variant_numbers}</Col>
  }

  return (
    <Modal
      className="edit-price-modal"
      visible={props.visible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      footer={false}
    > 
      <div className="header">
        <Title level={2}>Update Prices</Title>
        <Text className="font-small">For {state.experienceSelected ? state.experienceSelected.title : ""}</Text>
        <Divider />
      </div>
      <Form layout="horizontal" form={form} onValuesChange={onFormChange} hideRequiredMark wrapperCol={24} mode={"month"} onFinish={onFormSubmit}>
        <Row gutter={[20, 20]}>
          {renderVariantEditor()}
          <Col span={8} offset={8}>
            <Button loading={loading} block type="primary" size="large" htmlType="submit" onClick>
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
   )
}
export default ExperienceEditPriceModal
