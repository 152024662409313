import React, { useContext, useEffect, useState } from "react";
import { message, Button, Layout, Row, Col, Space, DatePicker, Typography, Tooltip } from "antd"
import { ArrowLeftOutlined, SyncOutlined } from '@ant-design/icons';
import ExperienceImage from "../components/experience_Image"
import { GlobalStateContext, GlobalDispatchContext } from "../context/GlobalContextProvider"
import { navigate } from "gatsby"
import ExperienceCard from "../components/experience_Card"
import ImpersonationBanner from "../components/impersonationBanner"
import EditExperiencePriceModal from "../components/experience_EditPriceModal"
import ViewExperienceCalendar from "../components/experience_ViewCalendar"
import LoadingPage from "../components/loadingPage"
import LoadingGIF from "../components/loadingGIF"

const { Header, Content } = Layout;
const { Title } = Typography;

const Experiences = () => {

  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showEditExperiencePriceModal, setShowEditExperiencePriceModal] = useState(false)

  useEffect(() => {
    if (state.auth_state)
      dispatch({ type: 'ROUTE', route: "/experiences" })
  }, [state.auth_state, dispatch])

  const handleRouting = () => {
    if (state.role_level > 0 && !state.profile)
      navigate('/users')
  }
  useEffect(handleRouting);

  const renderExperienceCalendar = () => {
    return <ViewExperienceCalendar headerRender={renderCalendarHeader} />
  }

  const handleShowCalendar = (experience) => {
    dispatch({ type: 'SELECT', toSelect: 'experienceSelected', value: experience });
    dispatch({ type: 'SELECT', toRefresh: 'refreshDateDictionary' });
    setShowCalendar(true)
  }

  const handleShowEditExperiencePriceModal = (experience) => {
    dispatch({ type: 'SELECT', toSelect: 'experienceSelected', value: experience });
    setShowEditExperiencePriceModal(true)
  }

  const renderExperienceCards = () => {
    if (state.experiencesLoaded) {
      let cards = []
      state.experiencesData.forEach((experience, index) => {
        cards.push(<Col key={index} span={12}>
          <ExperienceCard
            experience={experience}
            // onViewClick={() => handleShowViewExperienceDrawer(experience)}
            onPriceClick={() => handleShowEditExperiencePriceModal(experience)}
            onCalendarClick={() => handleShowCalendar(experience)} />
        </Col>)
      })
      console.log("CARDS LENGTH", cards.length)
      if (cards.length === 0) {
        cards.push(<div key={"none"}>No experiences for this vendor.</div>)
      }
      return cards
    }
  }

  // const [monthSelected, setMonthSelected] = useState(moment().format('YYYY-MM'))

  const handleExitImpersonation = () => {
    dispatch({ type: 'IMPERSONATE', profile: "", imp_username: "" });
    navigate('/users')
  }

  const handlePriceChange = () => {
    setShowEditExperiencePriceModal(false);
    message.info("Price updated!")
  }

  const refreshList = () => {
    dispatch({ type: 'REFRESH', toRefresh: 'refreshFrames' });
  }

  const renderExperienceHeader = () => {
    if (showCalendar && state.experienceSelected) {
      return (
        <Header id="header" className={"calendar-view"}>
          <Row justify="start" align='stretch' gutter={20}>
            <Col flex="90px" className="image">
              <ExperienceImage experience_image={state.experienceSelected.images.length > 0 ? state.experienceSelected.images[0].src : "no-image"} />
            </Col>
            <Col flex="auto" className="actions">
              <Row className="main-row" align="middle" >
                <Col className="title alt-font" span={24}>
                  <Title>{state.experienceSelected && state.experienceSelected.title}</Title>
                </Col>
              </Row>
              {renderCalendarHeader()}
            </Col>
          </Row>
        </Header>
      )
    }
    return (
      <Header id="header" className={showCalendar ? "calendar-view" : ""}>
        <Row className="main-row">
          <Col className="title alt-font" span={20}>
            <Title>Experiences</Title>
          </Col>
        </Row>
        <Row className="lower-row" justify="start" gutter={20}>
        </Row>
      </Header>
    )
  }

  const renderCalendarHeader = () => {
    let value = state.monthSelected;
    if (!value) return;
    return (
      <Row className="lower-row" justify="start" gutter={12}>
        <Col>
          <Button block onClick={() => setShowCalendar(false)}><ArrowLeftOutlined />Return</Button>
        </Col>
        <Col>
          <DatePicker
            allowClear={false}
            value={value}
            format={['DD/MM/YYYY', 'DD/MM/YY']}
            inputReadOnly
            disabled={!state.framesLoaded}
            onChange={newDate => {
              dispatch({ type: 'SELECT', toSelect: 'monthSelected', value: newDate });
            }}
            picker="month" />
        </Col>
        <Col flex={"40px"}>
          <Tooltip placement="right" title={"Refresh calendar"}>
            <Button
              icon={<SyncOutlined className="refreshIcon" spin={!state.framesLoaded} />}
              onClick={refreshList}
            />
          </Tooltip>

        </Col>
        <Col span={2}>
          <Space className={!state.framesLoaded ? "" : "hidden"} align='center'>
            <LoadingGIF width="30px" />
          </Space>
        </Col>
        {/* <Col className={`loading-container ${state.framesLoaded ? "hidden" : ""}`}>
          <div>Loading... <LoadingOutlined style={{fontSize: "20px"}} /></div>
        </Col> */}
      </Row>
    );
  }

  return (
    <div id="experiences-page" className="page">
      <ImpersonationBanner role_level={state.role_level} username={state.imp_username} profile={state.profile} onExit={handleExitImpersonation} />
      {renderExperienceHeader()}
      <Content>
        <LoadingPage className={state.bookingsLoaded && state.experiencesLoaded ? "loaded" : "loading"} />
        <Row className={showCalendar ? "content-container calendar-view" : "content-container"} gutter={[20, 20]}>
          {showCalendar ? renderExperienceCalendar() : renderExperienceCards()}
        </Row>
      </Content>
      <EditExperiencePriceModal
        experience={state.selectedExperience}
        visible={showEditExperiencePriceModal}
        onSuccess={handlePriceChange}
        onOk={() => setShowEditExperiencePriceModal(false)}
        onCancel={() => setShowEditExperiencePriceModal(false)} />
    </div>
  )
}

export default Experiences;
